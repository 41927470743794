import React, { useState, useRef, useCallback, useMemo, memo } from "react"
import { useSwipeable } from "react-swipeable"
import { IObjectKeys } from "../../../types"
import DeleteModal from "./delete-modal/DeleteModal"
import { useNavigate } from "react-router-dom"
import "./PostSwipableCard.scss"
import "./swipe-tabs/SwipeTabs.scss"
import MyOfferTabs from "./swipe-tabs/MyOfferTabs"
import SwipeCard from "./SwipeCard/SwipeCard"
import BaseOfferTabs from "./swipe-tabs/BaseOfferTabs"
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks/ReduxHooks"
import { getUser } from "../../../store/slices/auth"
import { favoriteOffer, hideOffer } from "../../../store/slices/offers"
import BoostCollapse from "./boost-collapse/BoostCollapse"
import dayjs from "dayjs"

interface PostSwipeableCardProps {
  offer: IObjectKeys
  isMyOffer?: boolean
  isFav?: boolean
  isHidden?: boolean
}

const PostSwipeableCard: React.FC<PostSwipeableCardProps> = ({
  offer,
  isMyOffer = false,
  isFav = false,
  isHidden = false,
}) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const [translateX, setTranslateX] = useState<number>(0)
  const [isHorizontalSwipe, setIsHorizontalSwipe] = useState<boolean>(false)
  const isSwipingRef = useRef(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
  const [hideModalVisible, setHideModalVisible] = useState<boolean>(false)
  const navigate = useNavigate()
  const isModerationRequested = offer.status === "REQUESTED_MODERATION"
  const isChangesRequested = offer.status === "WAITING_FOR_CHANGES"
  const isBoosted =
    dayjs().isBefore(dayjs(offer.homePagePinnedUntil)) ||
    dayjs().isBefore(dayjs(offer.categoryPinnedUntil))

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteModalVisible(false)
  }, [])

  const handleCloseHideModal = useCallback(() => {
    setHideModalVisible(false)
  }, [])

  const handleSwipedLeft = useCallback(() => {
    if (isMyOffer) {
      setDeleteModalVisible(true)
    } else if (isFav) {
      dispatch(
        favoriteOffer({ telegramId: user.telegramId, offerId: offer.id, isFavPage: isFav }),
      ).then(() => {})
    } else {
      if (isHidden) {
        dispatch(hideOffer({ telegramId: user.telegramId, offerId: offer.id })).then(() => {})
      } else {
        setHideModalVisible(true)
      }
    }
  }, [isMyOffer, offer, isFav, isHidden])

  const handleSwipeRight = useCallback(() => {
    if (isMyOffer) {
      navigate(
        `/offers/${offer.id}?isMe=true&editMode=true&telegramId=${offer.freelancer.telegramId}`,
      )
    } else {
      dispatch(
        favoriteOffer({ telegramId: user.telegramId, offerId: offer.id, isFavPage: isFav }),
      ).then(() => {})
    }
  }, [isMyOffer, offer, isFav])

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (isSwipingRef.current) {
        e.preventDefault()
        e.stopPropagation()
        isSwipingRef.current = false
      } else {
        if (isHidden) {
          return
        } else {
          navigate(
            `/offers/${offer.id}?isMe=${isMyOffer}&telegramId=${offer.freelancer.telegramId}`,
          )
        }
      }
    },
    [isMyOffer, offer],
  )

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      const { deltaX, deltaY } = eventData

      if (!isHorizontalSwipe) {
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          setIsHorizontalSwipe(true)
        } else {
          return
        }
      }

      isSwipingRef.current = true
      if ((isFav && deltaX > 0) || (isHidden && deltaX > 0)) {
        return
      }
      let newX = deltaX
      if (newX > 68) newX = 68
      if (newX < -68) newX = -68

      setTranslateX(newX)
    },
    onSwipedLeft: () => {
      if (isHorizontalSwipe && translateX <= -68) {
        handleSwipedLeft()
      }
      setTranslateX(0)
    },
    onSwipedRight: () => {
      if (isHorizontalSwipe && translateX >= 68) {
        handleSwipeRight()
      }
      setTranslateX(0)
    },
    onSwiped: () => {
      setIsHorizontalSwipe(false)
    },
    trackMouse: true,
    delta: 10,
    preventScrollOnSwipe: false,
  })
  const renderTabComponents = useMemo(() => {
    if (isMyOffer) {
      return <MyOfferTabs />
    } else {
      return <BaseOfferTabs isFavorite={offer.isFavorite} isFavPage={isFav} />
    }
  }, [isMyOffer, offer])
  return (
    <>
      {deleteModalVisible && (
        <DeleteModal
          visible={deleteModalVisible}
          onCloseModal={handleCloseDeleteModal}
          offerId={offer.id}
        />
      )}
      {hideModalVisible && (
        <DeleteModal
          visible={hideModalVisible}
          onCloseModal={handleCloseHideModal}
          offerId={offer.id}
          freelancer={user}
          isHide
        />
      )}
      <div {...handlers} className={`swipe-container`} style={{ touchAction: "pan-y" }}>
        {renderTabComponents}
        <SwipeCard
          isModerationRequested={isModerationRequested}
          isChangesRequested={isChangesRequested}
          translateX={translateX}
          offer={offer}
          isMyOffer={isMyOffer}
          isHidden={isHidden}
          handleClick={handleClick}
          isBoosted={isBoosted}
        />
      </div>
      {isMyOffer && !isChangesRequested && !isModerationRequested ? (
        <BoostCollapse offer={offer} />
      ) : null}
    </>
  )
}

const areEqual = (prevProps: PostSwipeableCardProps, nextProps: PostSwipeableCardProps) => {
  return (
    prevProps.isMyOffer === nextProps.isMyOffer &&
    prevProps.offer === nextProps.offer &&
    prevProps.isFav === nextProps.isFav &&
    prevProps.isHidden === nextProps.isHidden
  )
}

export default memo(PostSwipeableCard, areEqual)
