import React, { useState, useEffect, useRef, useCallback } from "react"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import StarIcon from "../../../../assets/images/svg/star.svg?react"
import "./BoostCollapse.scss"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks/ReduxHooks"
import { getUser } from "../../../../store/slices/auth"
import { IObjectKeys } from "../../../../types"
import { getOfferPinLoading, pinOffer } from "../../../../store/slices/offers"
import { useTranslation } from "react-i18next"

dayjs.extend(duration)

const BoostCollapse = ({ offer }: { offer: IObjectKeys }) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const collapseRef = useRef<HTMLDivElement | null>(null)
  const user = useAppSelector(getUser)
  const loading = useAppSelector(getOfferPinLoading)
  const dispatch = useAppDispatch()

  const [homePageTimer, setHomePageTimer] = useState("")
  const [categoryTimer, setCategoryTimer] = useState("")
  const [homePageDisabled, setHomePageDisabled] = useState(false)
  const [categoryDisabled, setCategoryDisabled] = useState(false)

  const getTimeLeft = (until: string | null) => {
    if (!until) return ""
    const diff = dayjs(until).diff(dayjs())

    if (diff <= 0) return ""

    const timeLeft = dayjs.duration(diff)
    return timeLeft.format("HH:mm:ss")
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const homePageTimeLeft = getTimeLeft(offer.homePagePinnedUntil)
      const categoryTimeLeft = getTimeLeft(offer.categoryPinnedUntil)

      setHomePageTimer(homePageTimeLeft)
      setCategoryTimer(categoryTimeLeft)
      setHomePageDisabled(!!homePageTimeLeft)
      setCategoryDisabled(!!categoryTimeLeft)
    }, 1000)

    return () => clearInterval(interval)
  }, [offer.homePagePinnedUntil, offer.categoryPinnedUntil])

  const handleClickOutside = (event: MouseEvent) => {
    if (collapseRef.current && !collapseRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  const handlePinOffer = useCallback(
    (type: "HOME_PAGE" | "CATEGORY") => {
      dispatch(pinOffer({ telegramId: user.telegramId, offerId: offer.id, type }))
        .then((res) => {
          // dispatch(updateSingleOffer(res?.payload))
        })
        .catch((error) => {
          console.error("Error submitting offer:", error)
        })
    },
    [offer, user, dispatch],
  )

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isOpen])

  return (
    <div className={`boost-collapse`} ref={collapseRef}>
      {!isOpen && (
        <button className='boost-collapse__button' onClick={() => setIsOpen(true)}>
          {t("pinned.boost")}
        </button>
      )}
      <div className={`boost-collapse__content ${isOpen ? "boost-collapse__content--open" : ""}`}>
        <div className={"boost-collapse__content--open__item"}>
          <div className={"boost-collapse__content--open__item--heading"}>
            <StarIcon />
            <p>{t("pinned.pinHome")}</p>
          </div>
          <button
            disabled={homePageDisabled || loading}
            onClick={() => handlePinOffer("HOME_PAGE")}
            className={"boost-collapse__content--open__item--btn"}
          >
            {homePageTimer ? `${homePageTimer}` : `${t("pinned.boost")}`}
          </button>
        </div>
        <div className={"boost-collapse__content--open__item"}>
          <div className={"boost-collapse__content--open__item--heading"}>
            <StarIcon />
            <p>{t("pinned.pinCategory")}</p>
          </div>
          <button
            disabled={categoryDisabled || loading}
            onClick={() => handlePinOffer("CATEGORY")}
            className={"boost-collapse__content--open__item--btn"}
          >
            {categoryTimer ? `${categoryTimer}` : `${t("pinned.boost")}`}
          </button>
        </div>
      </div>
    </div>
  )
}

export default BoostCollapse
