import React, { memo, useMemo, useState } from "react"
import { BASE_URL } from "../../../../constants"
import pic from "../../../../assets/images/other/image.png"
import RequestedModerationIcon from "../../../../assets/images/svg/requested-moderation.svg?react"
import ChangesRequestedCoverIcon from "../../../../assets/images/svg/changes-requested-cover.svg?react"
import InfoIcon from "../../../../assets/images/svg/info-icon.svg?react"
import TimeIcon from "../../../../assets/images/svg/time-icon.svg?react"
import AlertIcon from "../../../../assets/images/svg/alert-icon.svg?react"
import FavoriteIcon from "../../../../assets/images/svg/favorite-icon.svg?react"
import NotFavoriteIcon from "../../../../assets/images/svg/not-favorite-icon.svg?react"
import StarIcon from "../../../../assets/images/svg/star-icon.svg?react"
import CommentIcon from "../../../../assets/images/svg/comment-icon.svg?react"
import EyeIcon from "../../../../assets/images/svg/card-eye-icon-small.svg?react"
import HeartIcon from "../../../../assets/images/svg/card-heart-icon-small.svg?react"
import StartStatusIcon from "../../../../assets/images/svg/star-status.svg?react"
import StartStatusCoverIcon from "../../../../assets/images/svg/star-status-cover.svg?react"
import { IObjectKeys } from "../../../../types"
import dayjs from "dayjs"
import ReasonModal from "../reason-modal/ReasonModal"
import { useTranslation } from "react-i18next"

interface ISwipeCardProps {
  isModerationRequested: boolean
  isChangesRequested: boolean
  translateX: number
  offer: IObjectKeys
  isMyOffer: boolean
  isHidden: boolean
  handleClick: (e: React.MouseEvent) => void
  isBoosted: boolean
}

const SwipeCard: React.FC<ISwipeCardProps> = ({
  isModerationRequested,
  translateX,
  isMyOffer,
  offer,
  isHidden,
  isChangesRequested,
  handleClick,
  isBoosted,
}) => {
  const isBoostedHome = dayjs().isBefore(dayjs(offer.homePagePinnedUntil))
  const isBoostedCategory = dayjs().isBefore(dayjs(offer.categoryPinnedUntil))

  const { t } = useTranslation()
  const [reasonModalVisible, setReasonModalVisible] = useState(false)

  const detectBoostStatus = useMemo(() => {
    if (isBoostedHome && isBoostedCategory) {
      return t("pinned.boostedBoth")
    }
    if (isBoostedHome) {
      return t("pinned.boostedHome")
    }
    return t("pinned.boostedCategory")
  }, [isBoostedHome, isBoostedCategory])

  const renderUserInfoComponent = useMemo(() => {
    if (!isMyOffer) {
      return (
        <div className={"swipeable-card_body_content_user_info d-flex-center-aligned gap-12"}>
          <div
            className={"swipeable-card_body_content_user_info--item d-flex-center-aligned gap-8"}
          >
            <StarIcon />
            <p>5</p>
          </div>
          <div
            className={"swipeable-card_body_content_user_info--item  d-flex-center-aligned gap-8"}
          >
            <CommentIcon />
            <p>0</p>
          </div>
        </div>
      )
    } else {
      return (
        <div
          className={
            "swipeable-card_body_content_user_info swipeable-card_body_content_user_info-my d-flex-space-between-aligned"
          }
        >
          <div className={"d-flex-center-aligned gap-6"}>
            <div
              className={"swipeable-card_body_content_user_info--item d-flex-center-aligned gap-4"}
            >
              <CommentIcon />
              <p>N/A</p>
            </div>
            <div
              className={"swipeable-card_body_content_user_info--item  d-flex-center-aligned gap-4"}
            >
              <EyeIcon />
              <p>N/A</p>
            </div>
            <div
              className={"swipeable-card_body_content_user_info--item  d-flex-center-aligned gap-4"}
            >
              <HeartIcon />
              <p>N/A</p>
            </div>
          </div>
          <p className={"swipeable-card_body_content_user_info-my--date"}>
            {dayjs(offer?.updatedAt || offer?.createdAt).format("HH:mm  DD.MM.YYYY")}
          </p>
        </div>
      )
    }
  }, [isMyOffer, offer])
  return (
    <>
      {reasonModalVisible ? (
        <ReasonModal
          visible={reasonModalVisible}
          onCloseModal={() => setReasonModalVisible(false)}
          reason={offer.reason}
        />
      ) : null}
      <div
        className={"swipeable-card-wrapper"}
        style={{
          transform: `translateX(${translateX}px)`,
          transition: translateX === 0 ? "transform 0.3s ease-out" : "none",
        }}
      >
        <div
          className={`swipeable-card ${isModerationRequested || isChangesRequested ? "swipeable-card-disabled" : ""} ${isBoosted || offer.id === 62 ? "swipeable-card-boosted" : ""}`}
          onClick={handleClick}
        >
          <div className={"swipeable-card_body d-flex gap-8"}>
            <div className={"swipeable-card_body_cover d-flex-centered"}>
              {!isMyOffer && !isHidden ? (
                <div className={"swipeable-card_body_cover--favorite-icon"}>
                  {offer.isFavorite ? <FavoriteIcon /> : <NotFavoriteIcon />}
                </div>
              ) : null}
              <img
                src={offer?.thumbnail?.url ? `${BASE_URL}/${offer?.thumbnail?.url}` : pic}
                alt={offer?.title ? `${offer.title} cover` : "cover"}
                className='swipeable-card_body_cover--coverImg'
                loading='lazy'
                width='137'
                height='114'
              />
            </div>
            <div className={"swipeable-card_body_content d-flex-column-space-between"}>
              <h2 className={"swipeable-card_body_content--title"}>{offer?.title || "No title"}</h2>
              {isMyOffer && (
                <p className={"swipeable-card_body_content--category"}>
                  {t("category")}: <span>{offer?.subCategory?.name}</span>
                </p>
              )}
              <p
                className={`swipeable-card_body_content--text ${
                  isMyOffer ? "swipeable-card_body_content--text-mine" : ""
                }`}
              >
                {offer?.description || "No description"}
              </p>
              <div
                className={`swipeable-card_body_content_user d-flex-center-aligned ${
                  !isMyOffer ? "swipeable-card_body_content_user-with-img" : ""
                }`}
              >
                {!isMyOffer ? (
                  <div className={"swipeable-card_body_content_user__avatar d-flex-centered"}>
                    <div className={"swipeable-card_body_content_user__avatar--img"}>
                      <img
                        src={`${BASE_URL}/${offer?.freelancer?.avatarUrl}`}
                        alt=''
                        loading={"lazy"}
                      />
                    </div>
                  </div>
                ) : null}
                <div className={"swipeable-card_body_content_user-text d-flex-column gap-4"}>
                  {!isMyOffer ? (
                    <p className={"swipeable-card_body_content_user--name"}>
                      {offer?.freelancer?.username || "No username"}
                    </p>
                  ) : null}
                  {renderUserInfoComponent}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"container-padding"}>
          {isModerationRequested ? (
            <div className={"swipeable-card__status-moderation"}>
              <div className={"swipeable-card__status-moderation-text d-flex-center-aligned gap-8"}>
                <TimeIcon />
                <p>{t("underModeration")}</p>
              </div>
              <RequestedModerationIcon />
            </div>
          ) : null}
          {isBoosted || offer.id === 62 ? (
            <div className={"swipeable-card__status-boosted"}>
              <div className={"swipeable-card__status-boosted-text d-flex-center-aligned gap-8"}>
                <StartStatusIcon />
                <p>{detectBoostStatus}</p>
              </div>
              <StartStatusCoverIcon />
            </div>
          ) : null}
          {isChangesRequested ? (
            <div className={"swipeable-card__status-changes-requested d-flex-center-aligned gap-8"}>
              <div
                className={
                  "swipeable-card__status-changes-requested-text d-flex-center-aligned gap-8"
                }
              >
                <AlertIcon />
                <p>{t("sentToRevision")}</p>
              </div>
              <button
                onClick={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  setReasonModalVisible(true)
                }}
                className={
                  "swipeable-card__status-changes-requested-text-reason d-flex-center-aligned gap-8"
                }
              >
                <InfoIcon />
                <p>{t("reason")}</p>
              </button>
              <ChangesRequestedCoverIcon />
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

const areEqual = (prevProps: ISwipeCardProps, nextProps: ISwipeCardProps) => {
  return (
    prevProps.isMyOffer === nextProps.isMyOffer &&
    prevProps.offer === nextProps.offer &&
    prevProps.isModerationRequested === nextProps.isModerationRequested &&
    prevProps.translateX === nextProps.translateX &&
    prevProps.isHidden === nextProps.isHidden &&
    prevProps.isChangesRequested === nextProps.isChangesRequested &&
    prevProps.handleClick === nextProps.handleClick &&
    prevProps.isBoosted === nextProps.isBoosted
  )
}
export default memo(SwipeCard, areEqual)
