import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import api from "../../../api/api"
import { AxiosResponse } from "axios"
import { BASE_API_URL } from "../../../constants"
import { IObjectKeys } from "../../../types"

interface OffersState {
  offersSubmitting: boolean
  myOffers: IObjectKeys[]
  myOffersLoading: boolean
  myOffersCounts: IObjectKeys | null
  deleteOfferLoading: boolean
  singleOffer: IObjectKeys | null
  catalogOffers: IObjectKeys[]
  catalogOffersLoading: boolean
  searchOffers: IObjectKeys[]
  searchOffersLoading: boolean
  hideOfferLoading: boolean
  refetchOffers: boolean
  favoriteOfferLoading: boolean
  offerPinLoading: boolean
}

const initialState: OffersState = {
  offersSubmitting: false,
  myOffers: [],
  myOffersLoading: true,
  myOffersCounts: null,
  deleteOfferLoading: false,
  singleOffer: null,
  catalogOffers: [],
  catalogOffersLoading: true,
  searchOffers: [],
  searchOffersLoading: false,
  hideOfferLoading: false,
  refetchOffers: false,
  favoriteOfferLoading: false,
  offerPinLoading: false,
}

export const submitOffer = createAsyncThunk(
  "offer/submit",
  async (
    { telegramId, offerData }: { telegramId: string; offerData: IObjectKeys },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post<AxiosResponse<any>>(
        `${BASE_API_URL}freelancers/${telegramId}/offers`,
        offerData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      )

      return response.data
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message
      return rejectWithValue(errorMessage)
    }
  },
)

export const pinOffer = createAsyncThunk(
  "offer/pin",
  async (
    {
      telegramId,
      offerId,
      type,
    }: { telegramId: string; offerId: string; type: "HOME_PAGE" | "CATEGORY" },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post<AxiosResponse<any>>(
        `${BASE_API_URL}freelancers/${telegramId}/offers/${offerId}/pin`,
        { type },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      )

      return response.data
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message
      return rejectWithValue(errorMessage)
    }
  },
)

export const editOffer = createAsyncThunk(
  "offer/edit",
  async (
    {
      telegramId,
      offerData,
      offerId,
    }: { telegramId: string; offerData: IObjectKeys; offerId: string | number },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.patch<AxiosResponse<any>>(
        `${BASE_API_URL}freelancers/${telegramId}/offers/${offerId}`,
        offerData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      )

      return response.data
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message
      return rejectWithValue(errorMessage)
    }
  },
)

export const fetchMyOffers = createAsyncThunk<
  any,
  { telegramId: number | string; type: string; page: number; perPage: number }
>("app/fetchMyOffers", async ({ telegramId, type, page, perPage }): Promise<any> => {
  const response: AxiosResponse<any> = await api.get(
    `${BASE_API_URL}freelancers/${telegramId}/offers?type=${type}&page=${page}&perPage=${perPage}`,
  )
  return response.data
})

export const fetchOffersWithSearch = createAsyncThunk<
  any,
  {
    telegramId: number | string
    type: string
    page: number
    perPage: number
    search: string
    subCategoryId?: number
  }
>(
  "app/fetchOffersWithSearch",
  async ({ telegramId, type, page, perPage, search = "", subCategoryId }: any): Promise<any> => {
    const params = {
      type,
      page,
      perPage,
      search,
      ...(subCategoryId && { subCategoryId }),
    }

    const response: AxiosResponse<any> = await api.get(
      `${BASE_API_URL}freelancers/${telegramId}/offers`,
      { params },
    )
    return response.data
  },
)

export const fetchSingleOffer = createAsyncThunk<any, { telegramId: string; offerId: string }>(
  "app/fetchSingleOffer",
  async ({ telegramId, offerId }): Promise<any> => {
    const response: AxiosResponse<any> = await api.get(
      `${BASE_API_URL}freelancers/${telegramId}/offers/${offerId}`,
    )
    return response.data
  },
)

export const deleteOffer = createAsyncThunk(
  "offer/delete",
  async ({ telegramId, offerId }: { telegramId: string; offerId: string }, { rejectWithValue }) => {
    try {
      const response = await api.delete<AxiosResponse<any>>(
        `${BASE_API_URL}freelancers/${telegramId}/offers/${offerId}`,
      )
      return response.data
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message
      return rejectWithValue(errorMessage)
    }
  },
)

export const hideOffer = createAsyncThunk(
  "offer/hide",
  async ({ telegramId, offerId }: { telegramId: string; offerId: string }, { rejectWithValue }) => {
    try {
      const response = await api.post<AxiosResponse<any>>(
        `${BASE_API_URL}freelancers/${telegramId}/offers/${offerId}/hide`,
      )
      return response.data
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message
      return rejectWithValue(errorMessage)
    }
  },
)

export const favoriteOffer = createAsyncThunk(
  "offer/favorite",
  async (
    { telegramId, offerId, isFavPage }: { telegramId: string; offerId: string; isFavPage: boolean },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post<AxiosResponse<any>>(
        `${BASE_API_URL}freelancers/${telegramId}/offers/${offerId}/favorite`,
      )
      return { data: response.data, isFavPage: isFavPage }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message
      return rejectWithValue(errorMessage)
    }
  },
)

const offersSlice = createSlice({
  name: "offers",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(submitOffer.pending, (state) => {
        state.offersSubmitting = true
      })
      .addCase(submitOffer.fulfilled, (state) => {
        state.offersSubmitting = false
      })
      .addCase(submitOffer.rejected, (state, action) => {
        state.offersSubmitting = false
      })

      .addCase(pinOffer.pending, (state) => {
        state.offerPinLoading = true
      })
      .addCase(pinOffer.fulfilled, (state, action: any) => {
        state.offerPinLoading = false
        state.myOffers = state.myOffers.map((offer) => {
          if (offer.id === action?.payload?.id) {
            offer = action.payload
          }
          return offer
        })
      })
      .addCase(pinOffer.rejected, (state, action) => {
        state.offerPinLoading = false
      })

      .addCase(editOffer.pending, (state) => {
        state.offersSubmitting = true
      })
      .addCase(editOffer.fulfilled, (state) => {
        state.offersSubmitting = false
      })
      .addCase(editOffer.rejected, (state, action) => {
        state.offersSubmitting = false
      })
      .addCase(fetchMyOffers.pending, (state, action) => {
        state.refetchOffers = false
        if (action.meta.arg.type === "personal") {
          state.myOffersLoading = true
        } else {
          state.catalogOffersLoading = true
        }
      })
      .addCase(fetchMyOffers.fulfilled, (state, action) => {
        if (action.meta.arg.type === "personal") {
          state.myOffersLoading = false
          state.myOffers = [...state.myOffers, ...action.payload.offers] // Append new offers
          state.myOffersCounts = action.payload.counts
        } else {
          state.catalogOffersLoading = false
          state.catalogOffers = [...state.catalogOffers, ...action.payload.offers]
        }
      })
      .addCase(fetchMyOffers.rejected, (state, action) => {
        if (action.meta.arg.type === "personal") {
          state.myOffersLoading = false
        } else {
          state.catalogOffersLoading = false
        }
      })
      .addCase(fetchOffersWithSearch.pending, (state, action) => {
        state.searchOffersLoading = true
        state.refetchOffers = false
      })
      .addCase(fetchOffersWithSearch.fulfilled, (state, action) => {
        state.searchOffersLoading = false
        state.searchOffers = [...state.searchOffers, ...action.payload.offers]
      })
      .addCase(fetchOffersWithSearch.rejected, (state, action) => {
        state.searchOffersLoading = false
      })
      .addCase(fetchSingleOffer.pending, (state) => {
        state.singleOffer = null
      })
      .addCase(fetchSingleOffer.fulfilled, (state, action) => {
        state.singleOffer = action.payload
      })
      .addCase(fetchSingleOffer.rejected, (state, action) => {
        state.singleOffer = null
      })
      .addCase(deleteOffer.pending, (state) => {
        state.deleteOfferLoading = true
      })
      .addCase(deleteOffer.fulfilled, (state, action) => {
        state.deleteOfferLoading = false
        state.myOffers = state.myOffers.filter((offer) => offer.id !== action.meta.arg.offerId)
      })
      .addCase(deleteOffer.rejected, (state, action) => {
        state.deleteOfferLoading = false
      })
      .addCase(favoriteOffer.pending, (state) => {
        state.favoriteOfferLoading = true
      })
      .addCase(favoriteOffer.fulfilled, (state, action) => {
        state.favoriteOfferLoading = false
        if (!action.payload.isFavPage) {
          if (state.searchOffers.length) {
            state.searchOffers = state.searchOffers.map((offer) => {
              if (action.meta.arg.offerId == offer.id) {
                offer = { ...offer, ...action.payload.data }
              }
              return offer
            })
          }

          if (state.catalogOffers.length) {
            state.catalogOffers = state.catalogOffers.map((offer) => {
              if (action.meta.arg.offerId == offer.id) {
                offer = { ...offer, ...action.payload.data }
              }
              return offer
            })
          }
        } else {
          const isCatalogOffersEmpty =
            state.catalogOffers.length === 1 &&
            state.catalogOffers[0].id === action.meta.arg.offerId

          state.catalogOffers = state.catalogOffers.filter(
            (offer) => offer.id !== action.meta.arg.offerId,
          )
          if (isCatalogOffersEmpty) {
            state.refetchOffers = true
          }
        }
      })
      .addCase(favoriteOffer.rejected, (state, action) => {
        state.favoriteOfferLoading = false
      })
      .addCase(hideOffer.pending, (state) => {
        state.hideOfferLoading = true
      })
      .addCase(hideOffer.fulfilled, (state, action) => {
        state.hideOfferLoading = false
        // Check if removing this offer will result in empty lists
        const isCatalogOffersEmpty =
          state.catalogOffers.length === 1 && state.catalogOffers[0].id === action.meta.arg.offerId
        const isSearchOffersEmpty =
          state.searchOffers.length === 1 && state.searchOffers[0].id === action.meta.arg.offerId

        // Filter out the hidden offer from both lists
        state.catalogOffers = state.catalogOffers.filter(
          (offer) => offer.id !== action.meta.arg.offerId,
        )
        state.searchOffers = state.searchOffers.filter(
          (offer) => offer.id !== action.meta.arg.offerId,
        )
        if (isSearchOffersEmpty) {
          state.refetchOffers = true
        }
        // Set refetch to true if both lists will be empty
        if (isCatalogOffersEmpty) {
          state.refetchOffers = true
        }
      })
      .addCase(hideOffer.rejected, (state, action) => {
        state.hideOfferLoading = false
      })
  },
  reducers: {
    resetSingleOffer: (state) => {
      state.singleOffer = null
    },
    updateSingleOffer: (state, action: PayloadAction<any>) => {
      state.singleOffer = action.payload
    },
    resetMyOffers: (state) => {
      state.myOffers = []
      state.myOffersLoading = true
      state.myOffersCounts = null
    },

    resetCatalogOffers: (state) => {
      state.catalogOffers = []
      state.catalogOffersLoading = true
    },
    resetSearchOffers: (state) => {
      state.searchOffers = []
      // state.searchOffersLoading = true
    },
  },
})

export const {
  resetSearchOffers,
  resetSingleOffer,
  updateSingleOffer,
  resetCatalogOffers,
  resetMyOffers,
} = offersSlice.actions
export const offerSubmitLoading = (state: { offers: OffersState }) => state.offers.offersSubmitting
export const getOfferPinLoading = (state: { offers: OffersState }) => state.offers.offerPinLoading
export const getRefetchOffers = (state: { offers: OffersState }) => state.offers.refetchOffers
export const myOffersLoading = (state: { offers: OffersState }) => state.offers.myOffersLoading
export const myOffers = (state: { offers: OffersState }) => state.offers.myOffers
export const myOffersCounts = (state: { offers: OffersState }) => state.offers.myOffersCounts
export const getSingleOffer = (state: { offers: OffersState }) => state.offers.singleOffer
export const getCatalogOffers = (state: { offers: OffersState }) => state.offers.catalogOffers
export const getCatalogOffersLoading = (state: { offers: OffersState }) =>
  state.offers.catalogOffersLoading
export const getSearchOffers = (state: { offers: OffersState }) => state.offers.searchOffers
export const getSearchOffersLoading = (state: { offers: OffersState }) =>
  state.offers.searchOffersLoading

export default offersSlice.reducer
